<template>
  <div class="ho-my-vket-input-text">
    <label class="label" :class="[textError ? '-error' : '']">
      <slot name="before" />
      <template v-if="counter">
        <span class="counter">{{ count }}</span>
      </template>
      <template v-if="isLazy">
        <template v-if="isTrim">
          <input
            v-model.trim.lazy="text"
            :type="type"
            :placeholder="placeholder"
            :disabled="disabled"
            :required="required"
            :min="min"
            class="input"
            enterkeyhint="enter"
            @keyup.enter="enter"
          />
        </template>
        <template v-else>
          <input
            v-model.lazy="text"
            :type="type"
            :placeholder="placeholder"
            :disabled="disabled"
            :required="required"
            :min="min"
            class="input"
            enterkeyhint="enter"
            @keyup.enter="enter"
          />
        </template>
      </template>
      <template v-else-if="isTrim">
        <input
          v-model.trim="text"
          :type="type"
          :placeholder="placeholder"
          :disabled="disabled"
          :required="required"
          :min="min"
          class="input"
          enterkeyhint="enter"
          @keyup.enter="enter"
        />
      </template>
      <template v-else>
        <input
          v-model="text"
          :type="type"
          :placeholder="placeholder"
          :disabled="disabled"
          :required="required"
          :min="min"
          class="input"
          enterkeyhint="enter"
          @keyup.enter="enter"
        />
      </template>
      <slot name="after" />
    </label>
    <slot name="info" />
    <span class="error" v-text="textError" />
  </div>
</template>
<script lang="ts" setup>
import { useField } from 'vee-validate'
import { z, ZodType, ZodEffects, ZodTypeDef, ZodTypeAny } from 'zod'
import { toTypedSchema } from '@vee-validate/zod'

type FieldInput = string | number | null

type Props = {
  validatorName: string
  validatorRules?:
    | string
    | ZodType<string, ZodTypeDef, FieldInput>
    | ZodEffects<ZodTypeAny>
  placeholder?: string
  type?: string
  required?: boolean
  value?: string | number
  disabled?: boolean
  counter?: boolean | { max: number }
  min?: number | undefined
  keyupEnter?: boolean
  isLazy?: boolean
  isTrim?: boolean
}
const props = withDefaults(defineProps<Props>(), {
  placeholder: '',
  type: 'text',
  validatorRules: '',
  required: false,
  value: '',
  disabled: false,
  counter: false,
  min: undefined,
  keyupEnter: false,
  isLazy: false,
  isTrim: false,
})

type Emits = {
  (e: 'input', v: string): void
  (e: 'enter'): void
  (e: 'update:value', v: string): void
}
const emit = defineEmits<Emits>()

const { value: validateValue, errorMessage: textError } = useField<FieldInput>(
  toRef(props, 'validatorName'),
  typeof props.validatorRules === 'string'
    ? props.validatorRules
    : toTypedSchema(props.validatorRules),
  {
    initialValue: props.value,
  }
)

const text = computed({
  get(): string {
    const value = props.value + ''
    if (validateValue.value !== value) validateValue.value = value
    return value
  },
  set(text: string): void {
    emit('input', text)
    emit('update:value', text)
    validateValue.value = text
  },
})

const enter = () => {
  if (props.keyupEnter) {
    emit('enter')
  }
}

const count = computed((): string | number => {
  if (!props.validatorRules) return (props.value as string).length || '0'

  // zodを使う場合
  if (typeof props.validatorRules !== 'string') {
    const inputLength = text.value.length
    const max =
      typeof props.counter === 'object' ? props.counter.max : undefined
    const maxRuleLength = max ?? getMax(props.validatorRules?._def)
    return maxRuleLength ? `${inputLength}/${maxRuleLength}` : inputLength
  }

  if (!props.validatorRules.includes('max:'))
    return (props.value as string).length || '0'
  // maxバリデーションの数値を取得
  const index = props.validatorRules.indexOf('max:')
  const beforeTarget = props.validatorRules.substring(0, index)
  const maxAndAnyRule = props.validatorRules.replace(beforeTarget, '')
  if (maxAndAnyRule.includes('|')) {
    const pipeIndex = maxAndAnyRule.indexOf('|')
    const maxToLastStr = maxAndAnyRule.slice(pipeIndex)
    const maxRule = maxAndAnyRule.replace(maxToLastStr, '')
    const maxValue = maxRule.replace('max:', '')
    return `${(props.value as string).length || '0'}/${maxValue}`
  }
  const maxValue = maxAndAnyRule.replace('max:', '')
  return `${(props.value as string).length || '0'}/${maxValue}`
})
</script>
<style lang="scss" scoped>
@use '@/assets/styles/variables' as v;
@use '@/assets/styles/mixins' as m;

.ho-my-vket-input-text {
  > .label {
    // TODO: アイコンを入れたときのスタイルがgridに引っ張られて縦並びになるので直したい
    // いろんな個所でdeepで直しているからどこかでまとめて直したい
    align-items: end;
    background-color: v.$gray;
    border-radius: 5px;
    display: grid;
    grid-template-columns: auto;
    height: 44px;
    padding: 9px 12px 11px;
    position: relative;
    transition: background-color 0.1s ease;
    width: 100%;

    &:disabled {
      border-color: rgba(0, 0, 0, 0.12);
    }

    &:active,
    &:focus,
    &:hover,
    &:focus-within {
      background-color: v.$yellow-1;
    }

    &.-error {
      background-color: v.$red-1;
      border: 1px solid v.$red;

      > .input {
        color: v.$red;
      }
    }
  }

  > .label > .counter {
    display: block;
    font-size: 11px;
    position: absolute;
    right: 0;
    text-align: right;
    top: -18px;
  }

  > .label > .input {
    color: v.$base-font-color;
    width: 100%;
  }

  .input {
    color: v.$base-font-color;
    font-size: 16px;
    line-height: 24px;
    width: 100%;

    &::placeholder {
      color: v.$gray-5;

      @include m.sp() {
        font-size: 12px;
      }
    }

    &::selection {
      background-color: v.$primary-color;
      color: v.$white;
    }
  }

  > .error {
    color: v.$red;
    display: block;
    font-size: 12px;
    font-weight: 400;
    margin-bottom: v.space(4);
    margin-top: v.space(1);
    min-height: 20px;
    width: fit-content;
  }
}
</style>
